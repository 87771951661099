import Dropdown from '@stimulus-components/dropdown';
import RevealController from '@stimulus-components/reveal';
import TextareaAutogrow from "stimulus-textarea-autogrow";
import { application } from "./application";
import CaptchController from "./captcha_controller";
import EditorController from "./editor_controller";
import EventFormController from "./event_form_controller";
import FormController from "./form_controller";
import InputMaskController from "./input_mask_controller";
import NavigationController from "./navigation_controller";
import PageEventsController from "./pages/events_controller";

application.register("input-mask", InputMaskController);
application.register("editor", EditorController);
application.register("textarea-autogrow", TextareaAutogrow);
application.register("form", FormController);
application.register("page-events", PageEventsController);
application.register("captcha", CaptchController);
application.register("navigation", NavigationController);
application.register("reveal", RevealController);
application.register("dropdown", Dropdown);
application.register("event-form", EventFormController);

// Widgets
import WidgetCustomersGenderController from './widgets/customers_gender_controller';
import WidgetOccurredEventsController from './widgets/occurred_events_controller';

application.register("widget-occurred-events", WidgetOccurredEventsController);
application.register("widget-customers-gender", WidgetCustomersGenderController);
